<template>
    <v-app style="overflow-x: hidden;">
    <v-banner class="lavBanner" single-line color="#F5F8FD">
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/developPC_banner1-1.png"
        min-height="400"
        class="d-flex align-center"
        scale-down
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="pa-0 max-width-1200 mt-10" >
          <v-card color="transparent" flat class="banner-box">
            <v-card-title class="banner-title white--text pa-0 font-size-56 text-shadow-item font-weight-bold font-size-banner-title">
              创造属于你的领地
            </v-card-title>
            <v-card-title class="banner-text white--text pa-0 mt-3 font-size-20 text-shadow-item" style="line-height:32px">
              开始创作你的领地和内容
            </v-card-title>
            <!-- <v-btn
             width="128"
             class="font-size-14"
             color="rgba(25, 22, 38, 0.2)"
             style="border-radius: 5px;border: 1px solid wthite; "
             @click="currentHovershow = true"
          >
              下载编辑器
          </v-btn> -->
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <!-- 如何创造自己的领地 -->
    <v-card class="warps warp1 bgimg" color="#191B1D" >
      <div class="metaverse-title">
        <p>如何创造自己的领地</p>
      </div>
      <div >
         <v-img min-height="230" src='https://h5.ophyer.cn/official_website/other/develop1-1-1.png' contain>
         <template v-slot:placeholder><img-placeholder></img-placeholder></template>
         </v-img>
      </div>
      <div class="metaverse-title">
           <p>创造领地离不开强大的编辑器</p>
      </div>
    </v-card>
    <!-- VR全景编辑器功能介绍 -->
    <v-card class="warps warp2" flat color="transparent">
      <div class="Home-title" style="margin-bottom:20px;">
        <p class="font-size-24">VR全景编辑器功能介绍</p>
      </div>
      <v-tabs  centered  v-model="tab" fixed-tabs  hide-slider class="new-tabs-two" :class="isIE ? 'isIeClass': ''">
          <v-tab v-for="(item,ind) in list" :key="ind">
            <b>{{ item.title }}</b>
          </v-tab>
          <v-row  style="align-content: center;padding: 0 28px 0 216px;justify-content: end;" v-if="!isIE">
            <v-col v-for='(n,k) in list.length' :key='k' @click="gotab(k)" :cols="2.5" style="flex-grow:0;" class="pa-0">
               <div :class="k==tab?'lineclass--active':'lineclass'"></div>
            </v-col>
          </v-row>
       </v-tabs>
      <v-tabs-items v-model="tab">
       <v-tab-item v-for="(v, k) in list" :key="k" :transition="false">
          <div class="box">
            <transition name="slide-fade" appear >
              <div class="left" v-show="tab == k" style="position:relative">
                <canvas v-show="v.id == 4" :width="canvasWidth" :height="canvasHeight" :class="'aroundImgPreview'+v.id" @mousedown="handleCanvasDown" @mousemove="handleCanvasMove" @mouseup="handleCanvasUp"></canvas>
                <img :src="v.img" />
              </div>
            </transition>
            <transition name="slide-fade-reverse" appear >
              <v-card class="right  align-cente" v-show="tab == k" width="544" flat color="transparent">
                <div class="right-bj" :class="isChrome == 'isChrome' ? 'isChrome' : ''"></div>
                <div class="right-item">
                  <v-card-text class="title" v-text="v.h2" />
                  <v-card-text class="sub" v-text="v.sub" />
                  <v-card-text class="tip" v-text="v.content" v-html="v.content" />
                </div>
              </v-card>

            </transition>
          </div>
      </v-tab-item>
      </v-tabs-items>
      <div class="lineborder"></div>

      <!-- VR全景编辑器功能介绍 -->
      <div class="Home-title">
        <p class="font-size-24">VR全景编辑器使用场景</p>
      </div>
      <!-- VR全景编辑器使用场景 -->
      <v-container  class="d-flex justify-md-space-around mt-9">
        <div class="carousel-container">
          <div class="c-item-1 box-shadow" v-for='(item,ind) in VRList' :key='ind' :class="currentHover === (ind+1) ? 'c-item-hover':''" @mouseover="currentHover =(ind+1);">
            <div class="c-item-cover">  
              <v-img :src='item.img' width="248">
                <div class="coverleft" v-show='currentHover===(ind+1)'>
                   <v-img :src='item.icon' :width="item.width" class="mt-7"></v-img>
                   <h2 class="font-size-16">{{item.h2}}</h2>
                   <h3 class="font-size-14">{{item.sub}}</h3>
                   <p>{{item.p}}</p>
                </div>  
              </v-img>    
            </div>        
          </div>
        </div>
      </v-container>
    </v-card>

    <!-- 3D编辑器产品介绍 -->
    <v-card class="warps warp3" flat color="transparent">
      <!-- <div class="metaverse-title">
        <p class="font-size-24">3D编辑器产品介绍</p>
      </div>
      <v-row class="justify-center mb-11" style="max-width:1300px;margin:0 auto;">
          <v-col cols="3" v-for="(item,index) in product" :key="index" class="d-flex child-flex">
                <v-card flat color="transparent" >
                    <div>
                      <div class="d-flex justify-center">
                        <v-img :src="item.icon" width="128" class="flex-grow-0"  contain>
                          <template v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      size="80"
                                      color="red lighten-5"
                                    ></v-progress-circular>
                                  </v-row>
                          </template> 
                        </v-img>
                      </div>
                      <v-card-title class="white--text font-size-18 justify-center py-3" v-text="item.title"></v-card-title>
                      <div class="d-flex justify-center">
                        <v-card-text class="font-size-14 text-align-center justify-center py-0 px-12"  style="color: rgba(255, 255, 255, 0.7);;width:266px" v-text="item.content"></v-card-text>
                      </div>
                    </div>
                </v-card>
          </v-col>
      </v-row> -->
      <div class="lineborder"></div>
      <!-- 3编辑器使用场景 -->
      <div class="metaverse-title">
        <p class="font-size-24">3D编辑器使用场景</p>
      </div>
      <v-container  class="d-flex justify-md-space-around mt-9">
        <div class="carousel-container">
          <div class="c-item-1 box-shadow" :class="currentHoverTwo === 1 ? 'c-item-hover':''" @mouseover="currentHoverTwo = 1;">
            <v-card :class="currentHoverTwo === 1?'c-item-cover':'c-item-cover2'" color="#A5A5B3">  
              <div class="coverleft" v-show='currentHoverTwo=== 1'>
                   <img src='../../../../public/img/icons/develop/icon3D5-1@2.png' width="27" class="mt-7" />
                   <h2 class="font-size-16">3D场景搭建</h2>
                   <h3 class="font-size-14">快速搭建3D场景</h3>
                   <p>平台预设的3D模型，搭配用户上传的贴图可以快速搭建业务场景所需要的3D场景</p>
              </div>
              <div class="width-per-100 d-flex justify-center align-center">
                  <v-img src='https://h5.ophyer.cn/official_website/other/develop5-1new.png' contain max-width="328"></v-img> 
              </div>
            </v-card>        
          </div>
          <div class="c-item-2 box-shadow" :class="currentHoverTwo === 2 ? 'c-item-hover':''" @mouseover="currentHoverTwo = 2;">
            <v-card :class="currentHoverTwo === 2?'c-item-cover':'c-item-cover2'" color="#A5A5B3" class="width-per-1">
                <div class="coverleft" v-show='currentHoverTwo=== 2'>
                   <img src='../../../../public/img/icons/develop/icon3D5-2@2.png' width="26" class="mt-7" />
                   <h2 class="font-size-16">动画制作</h2>
                   <h3 class="font-size-14">序列帧、粒子动画快速生成</h3>
                   <p>序列帧动画编辑工具可以快速制作序列帧动画，内置粒子工具轻松制作粒子动画特效，让动画效果更上一层楼</p>
                </div>   
                <div class="width-per-100 d-flex justify-center align-center">
                  <v-img src='https://h5.ophyer.cn/official_website/other/develop5-2.png' max-width="328" contain class="position-r">
                    <div class="gifStyle" :class="currentHoverTwo === 2 ? '':'hideGif'"><img src="https://h5.ophyer.cn/official_website/other/WanqingClou-5-lizi.gif"/></div>
                  </v-img>  
                </div>  
            </v-card>
          </div>
          <div class="c-item-3 box-shadow" :class="currentHoverTwo === 3 ? 'c-item-hover':''" @mouseover="currentHoverTwo = 3;">
            <v-card :class="currentHoverTwo === 3?'c-item-cover':'c-item-cover2'" color="#A5A5B3">
              <div class="coverleft" v-show='currentHoverTwo=== 3'>
                   <img src='../../../../public/img/icons/develop/icon3D5-3@2.png' width="28" class="mt-7" />
                   <h2 class="font-size-16">脚本自定义</h2>
                   <h3 class="font-size-14">预设API，场景表现更随心</h3>
                   <p>通过预设API控制场景元素运动及动画的播放，让场景的表现更随心</p>
              </div>
              <div class="width-per-100 d-flex justify-center align-center">
                  <v-img src='https://h5.ophyer.cn/official_website/other/develop5-3.png' contain max-width="328"></v-img>
              </div>
            </v-card>
          </div>
        </div>
      </v-container>
    </v-card>
    <!-- 使用教程 -->
    <v-card class="warps warp5 " color="#191B1D" width="1200">
      <div class="metaverse-title">
        <p>使用教程</p>
      </div>
      <v-row class="width-per-100">
            <v-col v-for="(info,infoIndex) in data" :key="infoIndex" cols="3" class="pa-4" v-show='infoIndex<4'>
              <v-card
                  class="mx-auto item-card pa-0"
                  max-width="100%"
                  max-height="100%"
                  min-height="300"
                  @click="goCaseDetail(info.id)"
              >
                  <v-img
                      max-width="100%"
                      contain
                      class="tran-sec"
                      :src="info.coverImg"
                  >
                  </v-img>
                  <div class="text-box px-4">
                      <v-card-text class="font-size-14 sizeStyle pa-0 mt-4 mb-2 color-333 font-weight-bold title line-height-20" v-text="info.itemTitle"></v-card-text>
                      <v-card-text class="font-size-12 sizeStyle pa-0 pb-6 color-999 tip" v-text="info.itemTip"></v-card-text>
                  </div>
              </v-card>
            </v-col>
      </v-row>
      <p class="font-size-14 white--text mt-10 mb-10 more" style="text-align: center;" @click="gotoExperience">
        更多教程<img src="../../../assets/images/home/develop-icon.png" style="width:30px;margin-top:5px;" class="pl-2">
      </p>
    </v-card>

    <!-- 客服热线 -->
  <div class="dialog" v-show="currentHovershow" @click.stop="currentHovershow = false">
      <transition name="fade" mode="out-in" @click.stop>
        <div class="content-tryout" @click.stop>
          <v-card color="transparent" flat @click.stop>
            <v-card-title class="justify-end pb-0 close-btn" @click.stop="currentHovershow = false">
              <img width="11px" height="12px" src="https://h5.ophyer.cn/official_website/other/metaverse-diaog-clone.png" alt="">
              </v-card-title>
            <img  style="min-height: 180px;width: 480px;" src="https://h5.ophyer.cn/official_website/other/metaverse-diaog-bg.png" alt="">
            <div class="box">
              <img width="48px" height="48px" src="https://h5.ophyer.cn/official_website/other/metaverse-diaog-icon.png" alt="">
              <div>
                <p>4006189949</p>
                <p>客服热线</p>
              </div>
            </div>
          </v-card>
        </div>
      </transition>
    </div>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import contentData from '@/static/contentData.js';
import qs from 'qs';
import { browserVersion,browser } from "@/utils/utils";
const FramePlayer = () => import('frame-player');
export default {
  name: 'DevelopHome',
  components: {
  },
  data() {
    return {
      screenHeight: window.innerHeight,
      tab:null,
      currentHovershow: false,
      data: contentData.developCase[0].tabItem,
      list:contentData.DevelopHome.list,
      product:contentData.DevelopHome.product,
      currentHover: 1,
      currentHoverTwo:1,
      VRList:contentData.DevelopHome.VRList,
      framePlayer: null, //360物体预览对象
      around_play: false, //360物体播放控制
      around_scale: 100,
      sanliuleng: null,
      imgArr:contentData.DevelopHome.imgArr,
      canvasWidth:100,
      canvasHeight:100,
      isIE: browserVersion(),
      isChrome: false,
    }
  },
  created() {
    this.isChrome = browser()
    if(this.isIE) return;
    this.newFramePlayer();
  },
  computed: {
    ...mapState(["officialWebsite"]),
  },
  mounted() {
    // window.onresize = this.canvasWh;
    this.canvasWidth = document.body.clientWidth - 1200;
    this.canvasHeight = this.canvasWidth/1.734;
  },
  watch: {
    "officialWebsite.windowResize": function (){
      this.canvasWh();
    }
  },
  methods: {
    canvasWh(){
      this.canvasWidth = document.body.clientWidth - 1200;
      this.canvasHeight = this.canvasWidth/1.734;
      if(this.isIE) return;
      setTimeout(()=>{
        this.newFramePlayer();
      },100)
    },
    handleResize() {
      this.$refs.videos.style.marginLeft = (document.body.clientWidth-window.outerWidth)*0.71+'px'
    },
    gotab(k){
      this.tab=k
    },
    mouseOver(ind){
      this.active=ind
    },
    mouseOut(){
      this.active=0
    },
    goCaseDetail(id) {
      let query = {
        id: id,
      }
      let url = '/develop/ExperienceDetail?'+qs.stringify(query)
      window.open(url, '_blank')
      console.log(id)
    },
    gotoExperience(){
      this.$router.push('/develop/Experience')
    },
    aroundPlay(val) {
      if(this.framePlayer){
        if(val){
          this.framePlayer.play();
        }else {
          this.framePlayer.stop();
        }
      }
    },
    clickItem(id) {
      this.sanliuleng = id
      if(this.isIE) return;
      let _this = this
      setTimeout(()=>{
        _this.newFramePlayer();
      },100)
    },
    newFramePlayer() {
      if(this.sanliuleng != 4) return
      this.clearFramePlayer();
      this.$nextTick(()=>{
        this.framePlayer = new FramePlayer({
          canvas: ".aroundImgPreview4",
          width: this.canvasWidth,
          height: this.canvasHeight,
          images: this.imgArr,
          fps: 5,
          loop: -1,
          transparent: false,
          autoplay: false
        });
        this.framePlayer.on("ready",()=>{
          this.framePlayer.play(0);
          console.log(this.framePlayer)
        });
        this.framePlayer.on("update",(options)=>{
          this.frame = options.frame;
        });
      })
    },
    clearFramePlayer() {
      this.around_play = false;
      if(this.framePlayer){
        this.framePlayer.off("ready");
        this.framePlayer.off("update");
        this.framePlayer.stop();
        this.framePlayer = null;
      }
    },
    handleCanvasDown(e) {
      this.canvasDown = true;
      this.coordinate.x = e.clientX;
      this.coordinate.y = e.clientY;
    },
    handleCanvasMove(e) {
      if(this.canvasDown){
        if(e.clientX - this.coordinate.x > 5){
          if(this.frame === this.imgArr.length - 1){
            this.frame = 0;
          }else {
            this.frame++;
          }
          this.coordinate.x = e.clientX;
        }else if(this.coordinate.x - e.clientX > 5){
          if(this.frame === 0){
            this.frame = this.imgArr.length - 1;
          }else {
            this.frame--;
          }
          this.coordinate.x = e.clientX;
        }
        this.coordinate.y = e.clientY;
      }
    },
    handleCanvasUp (e){
      console.log(e)
      this.canvasDown = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.new-tabs-two .v-tab{
  margin: 0px !important;
}
.lavBanner {
  ::v-deep .v-banner__wrapper,
  ::v-deep .v-banner__content,
  ::v-deep .v-banner__text {
    padding: 0 !important;
    height: 101%;
    background: #2e2f33;
  }
}

.banner-box {
  .banner-title {
    position: relative;
    z-index: 3;
    text-shadow: 0px 0px 10px rgb(5 0 45 / 50%) !important;
  }
  .banner-text {
    position: relative;
    z-index: 3;
    text-shadow: 0px 0px 10px rgba(5, 0, 45, 0.5) !important;
  }
  &::after {
      content: "";
      background: url('https://h5.ophyer.cn/official_website/other/banner-bgs2.png');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 797px;
      height: 349px;
      position: absolute;
      bottom: -52px;
      left: 0;
      z-index: 2;

  }
}

::v-deep .cardbg{
  background: url('../../../../public/img/icons/develop/developbannerBg.png') ;
  background-position: center center;
}
::v-deep .theme--light.v-btn{
  // font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400 !important;
  color: #FFFFFF !important;
  border: 1px solid white !important;
  margin-top: 28px !important;
  position: relative;
  z-index: 99;
  .v-btn__content {
    text-shadow: 0px 0px 10px rgba(5, 0, 45, 0.5) !important;
  }
}
.metaverse-title {
    margin-top: 60px;
    margin-bottom: 50px;
    color: #FFFFFF;
    text-align: center;
    position: relative;
    z-index: 6;
    p {
      font-weight: 500;
      font-size: 32px;
      // font-family: PingFangSC-Medium, PingFang SC;
      color: #FFFFFF;
      line-height: 45px;
      text-shadow: 0px 0px 6px rgba(7, 0, 67, 0.3);
    }
}
.warps {
  margin: 0 auto;       
}

::v-deep .warp1 {
  width: 100%;
 .theme--light.v-image{
    max-width: 1120px;
    margin: 0 auto;
  }
}
.bgimg{
    width: 100%;
    background: url('https://h5.ophyer.cn/official_website/other/develop1-2.png');
    background-position: center center;
}
::v-deep .v-sheet.v-card{
  border-radius: unset;
}
::v-deep .warp2{
   width: 100%;
   background: linear-gradient(180deg, rgba(105, 116, 125, 0.2) 0%, rgba(49, 35, 216, 0.1) 100%);
   background-color: #191B1D;
   .theme--light.v-card{
     background: linear-gradient(180deg, rgba(105, 116, 125, 0.2) 0%, rgba(49, 35, 216, 0.1) 100%) !important;
     background-color: #191B1D;
   }
   .theme--light.v-tabs > .v-tabs-bar,.theme--light.v-tabs-items{
    background-color: transparent;
   }
   .v-tabs-bar__content{
     justify-content: start !important;
     width: 1232px !important;
     b {
       font-family: Microsoft YaHei;
     }
   }
   .lineclass{
     width: 40px;
     height: 4px;
     background: rgba(255, 255, 255, 0.3);
   }
   .lineclass--active{
     width: 40px;
     height: 4px;
     background: #FFFFFF;
   }
   .row{
     margin: 0 auto;
   }
   .v-tab::after{
     background-color: #ffffffb3 !important;
   }
   .v-tab{
     color: rgba(255, 255, 255, 0.7) !important;
     line-height: 21px !important;
     font-size: 15px;
    //  font-family: PingFangSC-Light, PingFang SC;
     font-weight: 300;
   }
   .v-tab--active{
     font-size: 18px;
    //  font-family: PingFangSC-Medium, PingFang SC;
     font-weight: 500;
     color: #FFFFFF !important;
     line-height: 25px !important;
     font-family: unset;
   }
   .isIeClass {
     padding: 0 150px !important;
     .v-tabs-bar__content {
       padding: 0 120px !important;
     }
   }
  .Home-title {
    margin-top: 80px;
    margin-bottom: 50px;
    color: #FFFFFF;
    text-align: center;
    position: relative;
    z-index: 6;
    p:nth-child(1) {
      font-weight: 500;
      font-size: 32px;
    }
    p:nth-child(2) {
      margin: 0 auto;
      max-width: 600px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 0;
    }
  }
  .box {
    width: 1280px;
    height: 420px;
    margin: 20px auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin-right: 55px;
    img {
      width: 728px;
      height: 420px;
      border-radius: 10px;
      object-fit: contain
    }
  }
  .right {
    position: relative;
    margin-left: -150px;
    width: 544px;
    height: 295px;
    border-radius: 5px;
    .right-bj{
      width: 100%;
      height: 100%;
      border-radius: 5px;
      background: linear-gradient(180deg, rgba(50, 55, 64, 0.9) 0%, rgba(37, 38, 67, 0.9) 100%);
      border: 1px solid rgba(255, 255, 255, 0.2);
      transition: 1s cubic-bezier(0.25, 0.8, 0.5, 1);
      z-index:1;
      backdrop-filter:  blur(15px);
    }
    .isChrome {
      background: linear-gradient(180deg, rgba(105, 116, 125, 0.2) 0%, rgba(49, 35, 216, 0.1) 100%) !important;
    }
    .right-item{
      width: 100%;
      height: 100%;
      padding-left: 40px;
      padding-top: 66px;
      position: absolute;    
      top: 0;
      left: 0;       
      text-align: left;   
      z-index:1;
      .v-card__text {
        padding: 0;
      }
      .title {
        font-size: 24px !important;
        // font-family: PingFangSC-Medium, PingFang SC !important;
        font-weight: 500 !important;
        color: #FFFFFF !important;
        line-height: 33px !important;
      }
      .sub{
        font-size: 16px !important;
        // font-family: PingFangSC-Regular, PingFang SC !important;
        font-weight: 400 !important;
        line-height: 22px !important;
        color: #FFFFFF !important;
        margin-top: 10px;
      }
      .tip {
        margin: 20px 0 !important;
        font-size: 14px !important;
        // font-family: PingFangSC-Light, PingFang SC !important;
        font-weight: 300 !important;
        color: #FFFFFF !important;
        line-height: 26px !important;
      }
     }
  }
  .lineborder{
    width: 1182px;
    height: 1px;
    background: #4C525D;
    border-radius: 1px;
    margin: 0 auto;
    margin-top: 70px;
  }
  .carousel-container {
    width: 1200px;
    height: 400px;
    position: relative;
    display: flex;
    flex-direction: row;
    animation: section-fade-in 1s ease 0s 1 normal both;
    margin-bottom: 60px;
    .coverleft{
      width: 180px;
      height: 380px;
      background: linear-gradient(180deg, rgba(41, 45, 49, 0.8) 0%, rgba(19, 15, 70, 0.7) 100%);
      border-radius: 5px 0px 0px 5px;
      padding-left:16px;
      padding-right: 10px;
      float: left;

      h2{
        font-size: 20px;
        // font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 28px;
        padding-top: 10px;
      }
      h3{
        font-size: 14px;
        // font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
        padding-top: 134px;
      }
      p{
        font-size: 12px;
        // font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.7);
        line-height: 17px;
        padding-top: 11px;
      }
    }
    .c-item-hover{
      width: 428px;
    }
    .c-item-1{
      margin-right: 10px;
      background-position: 0;
      overflow: hidden;
      position: relative;
      height: 388px;
      border-radius: 15px;
      transition: width .4s;
      transition-timing-function: ease;
    }
    .c-item-1:not(.c-item-hover){
      width: 180px;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .c-item-cover {
      width: 428px;
      height: 380px;
      border-radius: 14px;
      //opacity: .6;
      transition: all .4s;
      display: flex;
      transition-timing-function: ease;
    }
  }
}

::v-deep .warp3{
   width: 100%;
   background: linear-gradient(180deg, rgba(105, 116, 125, 0.2) 0%, rgba(49, 35, 216, 0.1) 100%);
   background-color: #191B1D;
   .theme--light.v-card{
     background-color:unset;
   }
   .metaverse-title{
     margin-bottom: 22px !important;
   }
   .lineborder{
    width: 1195px;
    height: 1px;
    background: #4C525D;
    border-radius: 0.5px;
    margin: 0 auto;
    margin-top: 40px;
  }
  .carousel-container {
    width: 1200px;
    height: 400px;
    position: relative;
    display: flex;
    flex-direction: row;
    animation: section-fade-in 1s ease 0s 1 normal both;
    margin-bottom: 60px;
    .coverleft{
      width: 240px;
      height: 380px;
      background: linear-gradient(180deg, rgba(41, 45, 49, 0.8) 0%, rgba(19, 15, 70, 0.7) 100%);
      border-radius: 5px 0px 0px 5px !important;
      padding-left:16px;
      padding-right: 10px;
      float: left;
      overflow: hidden;
      h2{
        font-size: 20px;
        // font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 28px;
        padding-top: 10px;
      }
      h3{
        font-size: 14px;
        // font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
        padding-top: 134px;
      }
      p{
        font-size: 12px;
        // font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.7);
        line-height: 17px;
        padding-top: 11px;
      }
    }
    .c-item-hover{
      width: 829px;
    }
    .c-item-1{
      margin-right: 10px;
      background-position: 0;
      overflow: hidden;
      position: relative;
      height: 388px;
      border-radius: 15px;
      transition: width .4s;
      transition-timing-function: ease;
    }
    .c-item-1:not(.c-item-hover){
      width: 240px;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .c-item-2{
      background-position: 0;
      margin-right: 10px;
      overflow: hidden;
      position: relative;
      height: 388px;
      border-radius: 15px;
      transition: width .4s;
      transition-timing-function: ease;
    }
    .c-item-2:not(.c-item-hover){
      width: 240px;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .c-item-3{
      background-position: 0;
      margin-right: 10px;
      overflow: hidden;
      position: relative;
      height: 388px;
      border-radius: 15px;
      transition: width .4s;
      transition-timing-function: ease;
    }
    .c-item-3:not(.c-item-hover){
      width: 240px;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .gifStyle{
      position: absolute;
      width:200px;
      height: 133px;
      top: calc(50% - 43px);
      left: 50%;
      transform: translate(-50%,-50%);
      img{
        width: 100%;
        height: 100%;
      }
    }
    .hideGif{
      left: calc(50% + 68px);
    }
    .c-item-cover {
      width: 829px;
      height: 380px;
      border-radius: 14px;
      transition: all .4s;
      display: flex;
      transition-timing-function: ease;
    }
    .c-item-cover2 {
      width: 829px;
      height: 380px;
      border-radius: 14px;
      transition: all .4s;
      display: flex;
      transition-timing-function: ease;
      .v-image__image{
        width: 328px;
        object-fit: contain;
        margin-left: 68px;
      }
    }
  }
  .col-3{
    margin-right: 100px !important;
  }
  .col-3:last-child{
    margin-right: 0px !important;
  }
}
::v-deep .item-card{
      padding: 10px;
      box-shadow: 0px 1px 14px 0px rgb(0 60 255 / 8%) !important;
      border-radius: 8px !important;
      transition: 0.3s ease;
      &:hover {
        transform: scale(1.04);
        box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
        // transform:translateY(-16px);
      }
      .text-box {
        .title {
          font-size: 14px !important;
          font-weight: 400;
          color: #333333;
          padding: 0 !important;
          margin-left: 3px;
          margin: 16px 0 8px 0px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 180px;
          text-align: start;
          height: unset;
        }
        &::after {
          content: "";
          background-image: url("../../../assets/images/home/case-icon.png");
          background-size: 100%;
          width: 20px;
          height: 20px;
          position: absolute;
          bottom: 45px;
          right: 10px;
        }
        &:focus {
          display: none !important;
        }
     
      }
}
.more{
  cursor: pointer;
}
.dialog {
  position: fixed;
  z-index:1001;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,.7);
  .content-tryout{
    width: 480px;
    height: 307px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #FFFFFF;
    box-shadow: 0px 0px 15px 5px rgb(0 114 255 / 10%);
    cursor: default;
    .v-card {
      cursor: default;
    }
    .close-btn{
      position: absolute;
      top: 0;
      right: 0;
      color: rgba(0, 0, 0, 0.6) !important;
      cursor: pointer;

    }
    .box {
      margin-top: 35px;
      display: flex;
      align-items: center;
      margin-left: 35px;
      cursor: default;
      img {
        width: 48px;
        height: 48px;
        margin-right: 5px;
        cursor: default;
      }
      p {
        margin-bottom: 0;
      }
      p:nth-child(1) {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
      }
      p:nth-child(2) {
        margin-top: 5px !important;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}
::v-deep .warp5{
  max-width: 1224px;
  .metaverse-title {
    margin-top: 80px;
    margin-bottom: 5px !important;
    color: #FFFFFF;
    text-align: center;
    position: relative;
    z-index: 6;
    p {
      font-weight: 500;
      font-size: 32px;
      // font-family: PingFangSC-Medium, PingFang SC;
      color: #FFFFFF;
      line-height: 45px;
      text-shadow: 0px 0px 6px rgba(7, 0, 67, 0.3);
    }
  }
}

#aroundImgPreview, .aroundImgPreview4 {
  border-radius: 30px;
  position: absolute;
  z-index: 2;
  left:0;
}
</style>
